var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"resource-date-picker ma-0 pa-0",staticStyle:{"min-width":"fit-content"},attrs:{"value":_vm.date,"prepend-icon":"mdi-calendar","readonly":"","hide-details":"","flat":"","solo":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{attrs:{"value":_vm.date,"no-title":"","day-format":_vm.formatDay},on:{"input":_vm.changeDate}})],1)],1),(_vm.time)?_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12"}},[_c('v-select',{staticClass:"ma-0 pa-0",staticStyle:{"min-width":"fit-content"},attrs:{"value":_vm.time,"items":_vm.times,"hide-details":"","flat":"","solo":"","menu-props":"auto","item-color":"#42A5F5"},on:{"change":_vm.changeTime},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%","letter-spacing":"1px"}},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"width":"100%","letter-spacing":"1px"}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2573636650)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }