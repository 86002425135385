<template>
  <div class="cr-resource-main">
    <ResourceList />
    <ResourceBookingView ref="ResourceBookingView" v-if="getResourceViewShow" />
    <BookingDialog ref="BookingDialog" v-if="getResourceDialogShow" />
    <v-overlay :value="getShowLoadingOverlay" z-index="20000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import Header from "./components/main/Header";
import ResourceList from "./components/main/ResourceList";
import BookingDialog from "./components/dialog/ResourceBookingDialog";
import ResourceBookingView from "./components/dialog/ResourceBookingView";
export default {
  components: {
    //Header,
    ResourceList,
    BookingDialog,
    ResourceBookingView
  },
  data() {
    return {
      showDatePicker: false
    };
  },
  created() {
    this.initData();
    this.setShowSplash(false);
  },
  computed: {
    ...mapGetters("resourceDialog", { getResourceDialogShow: "getShow" }),
    ...mapGetters("resourceView", { getResourceViewShow: "getShow" }),
    ...mapGetters("resource", ["getShowLoadingOverlay"])
  },
  methods: {
    ...mapActions("resourceRoute", ["initData"]),
    ...mapActions("splash", ["setShowSplash"])
  }
};
</script>

<style scoped>
.cr-resource-main {
  width: 100%;
  margin-left: 10px;
}
</style>
