import { mapGetters } from "vuex";

const ResourcePanelMixin = {
  data() {},
  computed: {
    ...mapGetters("resource", ["getResourcesByType", "getTypes"])
  },
  created() {},
  watch: {},
  methods: {
    isEmptyResources(type) {
      const typeResources = this.getResourcesByType(type);
      if (!typeResources || !typeResources.length) {
        return true;
      }
      return false;
    }
  }
};

export default ResourcePanelMixin;
