<template>
  <div class="resource-list-wrap">
    <div class="main-area">
      <div class="list-area">
        <LeftPanel
          ref="leftPanel"
          :resources="getResources"
          @getItemColor="getItemColor"
        />
        <RightPanel
          ref="rightPanel"
          :resources="getResources"
          @getItemColor="getItemColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import moment from "moment";
export default {
  components: {
    LeftPanel,
    RightPanel
  },
  data: () => ({
    drawer: true,
    hours: [],
    itemWidth: 56
  }),
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.scrollToElement();
    });
  },
  computed: {
    ...mapGetters("resource", ["getResources", "getTypes"])
  },
  watch: {
    getTypes: {
      handler(newValue) {
        newValue.forEach(el => {
          if (el.value == "ALL") {
            return;
          }
          const refName = `${el.value}_WRAP`;
          this.$refs.leftPanel.$refs[refName][0].style.display = el.isFolded
            ? "none"
            : "block";
          this.$refs.rightPanel.$refs[refName][0].style.display = el.isFolded
            ? "none"
            : "block";
        });
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations("resourceLayout", ["SET_SHOW_SETTING"]),
    ...mapActions("resourceRoute", ["initData"]),
    scrollToElement() {
      const el = this.$el.getElementsByClassName("list-area")[0];
      const currentHour = moment().hour();
      if (el) {
        el.scrollTo({
          left: currentHour * (this.itemWidth - 20)
        });
      }
    },
    getItemColor(type) {
      switch (type) {
        case "MEETING_ROOM":
          return "blue";
        case "CAR":
          return "red";
        default:
          return "yellow";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-list-wrap {
  height: 100%;
  width: 100%;
  .main-area {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    .list-area {
      overflow: scroll;
      height: calc(100vh - 65px);
      display: inline-flex;
      width: 100%;
    }
  }
  .card-wrap {
    overflow: hidden;
  }
}
</style>
