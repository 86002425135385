<template>
  <v-dialog
    :value="getShow"
    no-click-animation
    width="400px"
    max-width="500px"
    @click:outside="closeDialog"
  >
    <v-card class="resource-dialog">
      <v-card-title>
        <span class="text-h6 font-weight-bold" v-text="dialogTitle"></span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <!-- 자원 선택 -->
          <v-row>
            <v-col cols="12">
              <v-hover v-slot="{ hover }">
                <v-select
                  v-model="resourceId"
                  :items="getResources"
                  item-text="name"
                  item-value="id"
                  class="simple-select"
                  :background-color="
                    hover ? `grey lighten-3` : 'grey lighten-4'
                  "
                  hide-details
                  solo
                  flat
                  :item-disabled="excludeHeader"
                >
                  <template #item="{ item }">
                    <span class="px-2" v-if="item.type != 'ITEM_HEADER'"
                      >└</span
                    >
                    <span>
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-hover>
            </v-col>
          </v-row>
          <!-- 예약 사유 -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                required
                autofocus
                ref="reason"
                :label="$t('resource.reason') + '*'"
                autocomplete="off"
                v-model="reason"
                @keydown.enter="onEnter"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- 종일 여부 -->
          <v-row class="mt-0" align="center" justify="center">
            <v-col cols="12">
              <v-switch
                class="mt-0"
                dense
                inset
                hide-details
                :ripple="false"
                v-model="allDay"
                :label="$t('calendar.종일')"
              ></v-switch>
            </v-col>
          </v-row>
          <!-- 예약 시간 -->
          <v-row align="center" justify="center">
            <v-col cols="5">
              <DateTimePicker
                :date="startDate"
                :time="allDay ? null : startTime"
                @update:date="startDateChange"
                @update:time="startTimeChange"
              />
            </v-col>
            <v-col cols="2" class="text-center">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col cols="5">
              <DateTimePicker
                :date="endDate"
                :time="allDay ? null : endTime"
                @update:date="endDateChange"
                @update:time="endTimeChange"
              />
            </v-col>
          </v-row>
          <!-- 예약 설명 -->
          <v-row>
            <v-col cols="12">
              <v-textarea
                ref="description"
                outlined
                multi-line
                hide-details
                :label="$t('resource.description')"
                autocomplete="off"
                v-model="description"
                @keydown.enter="onEnter"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text outlined @click="closeDialog">
          {{ $t("common.닫기") }}
        </v-btn>
        <v-btn
          v-if="dialog.mode == modeTypes.save"
          outlined
          color="accent"
          @click="bookResource"
        >
          {{ $t("common.저장") }}
        </v-btn>
        <v-btn v-else outlined color="accent" @click="updateBooking">
          {{ $t("common.수정") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "@/commons/utils/validation";
import i18n from "@/_locales";
import DateTimePicker from "./DateTimePicker";
import moment from "moment-timezone";

export default {
  components: {
    DateTimePicker
  },
  created() {
    this.dialog.mode = this.getMode;
    this.initData();
    let selectedResourceId = this.getResourceId;
    if (selectedResourceId == 0) {
      selectedResourceId = this.getResources.find(
        resource => resource.type !== "ITEM_HEADER"
      )?.id;
    }
    this.resourceId = selectedResourceId;
  },
  data: () => ({
    dialog: {
      mode: "SAVE"
    },
    modeTypes: RESOURCE_CONSTANTS.mode,
    dialogTitle: i18n.t("resource.request.save"),
    id: 0,
    resourceId: 0,
    reason: "",
    description: "",
    icsCalName: "",
    allDay: false,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    eventId: 0
  }),
  computed: {
    ...mapGetters("resource", ["getResources", "getCurrentDate"]),
    ...mapGetters("resourceDialog", [
      "getShow",
      "getBooking",
      "getResourceId",
      "getMode"
    ])
  },
  methods: {
    ...mapActions("resourceDialog", [
      "closeResourceBookingDialog",
      "saveBooking",
      "updateResourceBooking"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    onEnter() {
      this.dialog.type == RESOURCE_CONSTANTS.mode.save
        ? this.bookResource()
        : this.updateCalendar();
    },
    initData() {
      const {
        id,
        resourceId,
        bookerId,
        bookerName,
        startDate,
        startTime,
        endDate,
        endTime,
        reason,
        eventId,
        description,
        allDay
      } = this.getBooking;

      const isUpdateMode = this.getMode === RESOURCE_CONSTANTS.mode.update;
      const startDt = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm");
      const endDt = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm");
      const startHour = CAL_CONSTANTS.findNearestTime(startDt, "HH");
      const startMinute = CAL_CONSTANTS.findNearestTime(startDt, "mm");
      const endHour = CAL_CONSTANTS.findNearestTime(endDt, "HH");
      const endMinute = CAL_CONSTANTS.findNearestTime(endDt, "mm");

      this.startDate = startDt.format("YYYY-MM-DD");
      this.endDate = endDt.format("YYYY-MM-DD");
      this.startTime = `${startHour}:${startMinute}`;
      this.endTime = `${endHour}:${endMinute}`;

      if (isUpdateMode) {
        this.dialogTitle = i18n.t("resource.request.update");
        Object.assign(this, {
          id,
          resourceId,
          bookerId,
          bookerName,
          reason,
          description,
          eventId,
          allDay
        });
      }
    },
    resourceValidation() {
      const { reason } = this;
      this.calendarTitle = reason.trim();

      // 사유 검사
      if (isEmpty(this.reason)) {
        // 아무것도 입력안한 후 확인을 누를 시, 부모컴포넌트에 있는 confirm값을 바꿔주어야 한다.
        this.$emit("update:confirm", false);
        this.$refs.reason.focus();
        return false;
      }
      return true;
    },
    async updateBooking() {
      // 유효성 검사
      if (!this.resourceValidation()) return;

      const booking = {
        id: this.id,
        resourceId: this.resourceId,
        bookerId: this.bookerId,
        bookerName: this.bookerName,
        reason: this.reason,
        startDate: this.startDate,
        startTime: this.allDay ? "00:00" : this.startTime,
        endDate: this.endDate,
        endTime: this.allDay ? "00:00" : this.endTime,
        allDay: this.allDay,
        description: this.description,
        eventId: null
      };
      await this.updateResourceBooking(booking);
    },
    closeDialog() {
      this.closeResourceBookingDialog();
    },
    excludeHeader(item) {
      if (item.type == "ITEM_HEADER") {
        return true;
      }
      return false;
    },
    startDateChange(date) {
      this.startDate = date;
      this.adjustDateTime("end");
    },
    endDateChange(date) {
      this.endDate = date;
      this.adjustDateTime("start");
    },
    startTimeChange(time) {
      this.startTime = time;
      this.adjustDateTime("end");
    },
    endTimeChange(time) {
      this.endTime = time;
      this.adjustDateTime("start");
    },
    adjustDateTime(adjustTarget) {
      const MINUTE_UNIT = 30;
      const startDateTime = moment(
        `${this.startDate} ${this.startTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const endDateTime = moment(
        `${this.endDate} ${this.endTime}`,
        "YYYY-MM-DD HH:mm"
      );

      if (startDateTime.isBefore(endDateTime)) return;

      if (adjustTarget === "start") {
        startDateTime.set({
          year: endDateTime.get("year"),
          month: endDateTime.get("month"),
          date: endDateTime.get("date")
        });

        if (startDateTime.isSameOrAfter(endDateTime)) {
          startDateTime.set({
            hour: endDateTime.get("hour"),
            minute: endDateTime.get("minute")
          });

          startDateTime.subtract(MINUTE_UNIT, "minutes");
        }

        this.startDate = startDateTime.format("YYYY-MM-DD");
        this.startTime = startDateTime.format("HH:mm");
      }

      if (adjustTarget === "end") {
        endDateTime.set({
          year: startDateTime.get("year"),
          month: startDateTime.get("month"),
          date: startDateTime.get("date")
        });

        if (startDateTime.isSameOrAfter(endDateTime)) {
          endDateTime.set({
            hour: startDateTime.get("hour"),
            minute: startDateTime.get("minute")
          });

          endDateTime.add(MINUTE_UNIT, "minutes");
        }

        this.endDate = endDateTime.format("YYYY-MM-DD");
        this.endTime = endDateTime.format("HH:mm");
      }
    },
    async bookResource() {
      if (!this.resourceValidation()) return;

      const booking = {
        resourceId: this.resourceId,
        reason: this.reason,
        startDate: this.startDate,
        startTime: this.allDay ? "00:00" : this.startTime,
        endDate: this.endDate,
        endTime: this.allDay ? "00:00" : this.endTime,
        allDay: this.allDay,
        eventId: null,
        description: this.description
      };

      await this.saveBooking(booking);
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-dialog ::v-deep {
  .container {
    padding: 0px;
  }
  textarea {
    resize: none;
  }
  .v-card__actions {
    padding: 0px 24px;
  }
}
</style>
