<template>
  <v-menu
    :value="getShow"
    :position-x="getPositionX"
    :position-y="getPositionY"
    :close-on-click="true"
    :close-on-content-click="false"
    absolute
    max-width="400px"
    @input="value => !value && closeResourceBookingView()"
  >
    <v-card min-width="350px" class="booking-card">
      <!-- 상단 버튼 -->
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <!-- modify -->
        <v-btn
          v-if="hasWritePrivilege"
          color="grey darken-2"
          fab
          icon
          small
          @click="openBookingDialog()"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <!-- trash -->
        <v-btn
          v-if="hasWritePrivilege"
          color="grey darken-2"
          fab
          icon
          small
          @click="openCancelConfirm(booking.id)"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <!-- close -->
        <v-btn
          class="ml-4 btn-close"
          color="grey darken-2"
          fab
          icon
          small
          @click.stop="closeResourceBookingView"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- 바디 -->
      <v-sheet
        max-height="480px"
        class="px-7 pb-6 text-body-2"
        style="overflow-x: hidden; overflow-y: auto"
      >
        <!-- 제목 -->
        <IconField
          dense
          icon="mdi-checkbox-blank"
          :color="getItemColor(booking.resource.type)"
          class="mb-3"
        >
          <IconFieldContent>
            <span class="text-h6 grey--text text--darken-4 font-weight-bold">
              {{ booking.reason }}
            </span>
            <!-- 시간 -->
            <span class="d-block text-subtitle-2">
              {{ getFormattedDateTimeText(booking) }}
            </span>
          </IconFieldContent>
        </IconField>

        <!-- 생성자 -->
        <IconField
          v-if="!!getBooking.bookerName"
          icon="mdi-account-multiple-outline"
          dense
        >
          <IconFieldContent>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-text="booking.bookerName"
                  v-bind="attrs"
                  v-on="on"
                ></span>
              </template>
              <span v-text="$t('resource.booker')"></span>
            </v-tooltip>
          </IconFieldContent>
        </IconField>

        <!-- 일정과 연동된 경우 -->
        <IconField v-if="booking.eventId" icon="mdi-calendar" dense>
          <IconFieldContent>
            <span class="event-span" @click="viewEventDetail($event)">{{
              setEventSummary
            }}</span>
            <a class="cr-link ml-1" @click.stop="openNewCalendarTab()"
              ><img
                style="width: 20px; position: absolute;"
                src="../../../../commons/assets/open-new-tab.png"
            /></a>
          </IconFieldContent>
        </IconField>
      </v-sheet>
    </v-card>
  </v-menu>
</template>

<script>
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";
import { getEventDetail } from "@/calendar/api/cal.api";
import { isEmpty } from "@/commons/utils/validation";
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";
import moment from "moment";
import IconField from "@/calendar/components/common/IconField.vue";
import IconFieldContent from "@/calendar/components/common/IconFieldContent.vue";

export default {
  components: {
    IconFieldContent,
    IconField
  },
  data: () => ({
    modeTypes: {},
    booking: {},
    event: {}
  }),
  async created() {
    await this.initData();
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("resourceView", [
      "getShow",
      "getPositionX",
      "getPositionY",
      "getBooking"
    ]),
    ...mapGetters("calEventView", ["isEventViewMenuOpened"]),
    hasWritePrivilege() {
      const user = this.getUserInfo;
      if (user.id === this.getBooking.bookerId) {
        return true;
      }
      return false;
    },
    setEventSummary() {
      return this.event?.detail?.summary;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("resourceView", ["closeResourceBookingView"]),
    ...mapActions("resourceDialog", [
      "openResourceBookingDialog",
      "cancelBooking"
    ]),
    ...mapMutations("calEventView", [
      "SET_EVENT_VIEW_SHOW",
      "SET_EVENT_VIEW_DATA"
    ]),
    getItemColor(type) {
      switch (type) {
        case "MEETING_ROOM":
          return "blue";
        case "CAR":
          return "red";
        default:
          return "yellow";
      }
    },
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    openCancelConfirm(bookingId) {
      let message = i18n.t("resource.confirm.cancel");
      this.openConfirmDialog("", message, async () => {
        await this.cancelBooking(bookingId);
      });
    },
    async openBookingDialog() {
      const booking = this.getBooking;
      await this.closeResourceBookingView();
      this.openResourceBookingDialog({
        mode: RESOURCE_CONSTANTS.mode.update,
        resourceId: booking.resourceId,
        booking: booking
      });
    },
    getFormattedDateTimeText(booking) {
      const { startDate, startTime, endDate, endTime, allDay } = booking;
      const startMoment = moment(startDate, "YYYY-MM-DD");
      const endMoment = moment(endDate, "YYYY-MM-DD");
      const dayDiff = endMoment.diff(startMoment, "days", false);

      let txt = startDate;

      if (dayDiff === 0) {
        txt += allDay ? "" : ` ${startTime} ~ ${endTime}`;
      } else if (dayDiff > 0) {
        txt += allDay
          ? ` ~ ${endDate}`
          : ` ${startTime} ~ ${endDate} ${endTime}`;
      }

      return txt;
    },
    setIcon(itemType) {
      return RESOURCE_CONSTANTS.typeIcons[itemType];
    },
    async initData() {
      this.modeTypes = RESOURCE_CONSTANTS.mode;
      this.booking = this.getBooking;
      if (!isEmpty(this.booking.eventId)) {
        const { status, data = {} } = await getEventDetail(
          this.booking.eventId
        );
        if (status != 200) {
          this.booking["eventId"] = null;
        }

        this.event = await data;
      }
    },
    viewEventDetail(nativeEvent) {
      const open = () => {
        this.SET_EVENT_VIEW_DATA({
          selectedEvent: this.event,
          selectedElement: nativeEvent.target
        });
        requestAnimationFrame(() =>
          requestAnimationFrame(() => this.SET_EVENT_VIEW_SHOW(true))
        );
      };
      if (this.isEventViewMenuOpened) {
        this.SET_EVENT_VIEW_SHOW(false);
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    openNewCalendarTab() {
      if (!this.event.detail) {
        return;
      }
      const startDate = moment(new Date(this.event.detail.dtStart));
      let route = this.$router.resolve({
        name: "cal_frame",
        params: {
          type: "day",
          focus: startDate.format("YYYY-MM-DD")
        }
      });
      window.open(route.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.booking-card ::v-deep {
  /* 팝업 본문 */
  .container {
    user-select: text;
  }

  /* 이벤트 팝업 */
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    display: none;
  }
  /* textfield 밑줄 제거 */
  .v-input__slot::before {
    border-style: none !important;
  }
  /* 참석자 */
  .text-no-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  /* 내용 */
  .description {
    white-space: pre;
  }
  .row {
    height: 35px;
  }
  .event-span {
    cursor: pointer;
  }
  .cr-link {
    color: var(--v-anchor-base);
    cursor: pointer;
  }
  .cr-link-icon {
    display: none;
    margin-bottom: 2px;
    margin-left: 4px;
  }

  &:hover {
    border-color: #110c321f;
    .cr-link-icon {
      display: inline-flex;
    }
  }
}
</style>
