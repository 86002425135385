<template>
  <div class="right-panel" @mouseup.stop="endDrag($event)">
    <div class="list-time-area">
      <div
        v-for="hour in hours"
        :key="hour"
        v-text="hour"
        :class="`time-block-${hour} text-body-2 font-weight-bold`"
        :ref="`time_block_${hour}`"
      ></div>
    </div>

    <!-- 시간 테이블 -->
    <div class="booking-area">
      <div ref="currentTimeLine" class="current-time-line"></div>
      <template v-for="(type, i) in getActualTypes">
        <!-- 아이템 카테고리 -->
        <div :key="`HEADER_${i}`" class="item-header"></div>
        <v-divider
          :key="'booking_header_line_' + i"
          class="divider-line"
        ></v-divider>
        <!-- 아이템 목록 -->
        <div :key="`ITEM_${i}`" :ref="`${type.value}_WRAP`" class="item-wrap">
          <template v-for="(resource, j) in getResourcesByType(type.value)">
            <div class="booking-row pa-0 ma-0 d-flex" :key="`ITEM_${j}`">
              <div
                v-for="(n, hour) in 48"
                :key="'half_hour_' + n"
                :class="
                  `item-time-blocks ${resource.id} ${hour} ${
                    hour % 2 == 0 ? 'half-hour' : ''
                  }`
                "
                :ref="`item_block_${resource.id}_${hour}`"
                @mousedown.stop="startDrag($event, resource.id, hour)"
                @mousemove="moveDrag($event, resource.id, hour)"
                @mouseup.stop="endDrag($event, resource.id, hour)"
              ></div>
              <!-- 예약된 자원 목록 -->

              <template v-for="(book, k) in resource.bookingList">
                <v-card
                  class="resource-booking-item"
                  ref="resource-booking-item"
                  v-if="resource.id === book.resourceId"
                  :key="'book_' + k"
                  dark
                  outlined
                  :color="getItemColor(resource.type)"
                  :width="calculateWidth(book)"
                  :style="`left: ${calculateLeft(book)}px; position:absolute;`"
                  @click.stop="showDetail($event, book)"
                >
                  <v-row class="no-gutters fill-height">
                    <div
                      class="col blue-grey--text fill-height px-2 py-1"
                      style="width: 95%;"
                    >
                      <div
                        class="white--text font-weight-bold text-truncate text-reason"
                      >
                        {{ book.reason }}
                      </div>
                      <div class="white--text text-truncate text-time">
                        {{ generateTimeText(book) }}
                      </div>
                    </div>
                  </v-row>
                </v-card>
              </template>
            </div>
            <v-divider :key="'booking_row_line_' + j"></v-divider>
          </template>
          <template v-if="isEmptyResources(type.value)">
            <div class="resource-empty-area">
              {{ $t("resource.alert.empty") }}
            </div>
            <v-divider
              :key="'booking_bottom_line_' + i"
              class="divider-line"
            ></v-divider>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResourcePanelMixin from "@/resource/mixins/resourcePanelMixin";
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";
import styleVars from "../../../style/scss/_resource.scss";
import moment from "moment";
export default {
  mixins: [ResourcePanelMixin],
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    hours: [],
    showMenu: false,
    x: 0,
    y: 0,
    itemWidth: styleVars.rightPanelItemWidth.replace(/\D/g, ""), // 해당 sass 변수에 px 문자열이 붙기 때문에 숫자만 유지.
    dragData: {
      isDragged: false,
      resourceId: 0,
      startHour: 0,
      endHour: 0
    }
  }),
  created() {
    this.generateHours();
    // 환경설정 닫기
    //this.SET_SHOW_SETTING(false);
    //this.initData();
  },
  mounted() {
    this.$nextTick(() => {
      this.moveCurrentTimeLine();
    });
  },
  watch: {
    getCurrentDate() {
      this.moveCurrentTimeLine();
    }
  },
  computed: {
    ...mapGetters("resourceView", ["getShow"]),
    ...mapGetters("resource", [
      "getActualTypes",
      "getResourcesByType",
      "getResources",
      "getCurrentDate"
    ]),
    isCurrentDate() {
      const selectedDate = this.getCurrentDate;
      const currentDate = moment().format("YYYY-MM-DD");
      if (selectedDate === currentDate) {
        return "block";
      }
      return "none";
    }
  },
  methods: {
    ...mapActions("resourceView", [
      "openResourceBookingView",
      "closeResourceBookingView"
    ]),
    ...mapActions("resourceDialog", ["openResourceBookingDialog"]),
    generateHours() {
      const hours = [];
      for (let hour = 0; hour < 24; hour++) {
        hours.push(moment({ hour }).format("HH"));
      }
      this.hours = hours;
    },
    calculateLeft(booking) {
      const { startDate, startTime, endDate } = booking;
      const currentDate = this.getCurrentDate;
      const startMoment = moment(startDate, "YYYY-MM-DD");
      const endMoment = moment(endDate, "YYYY-MM-DD");
      const dayDiff = endMoment.diff(startMoment, "days", false);

      if (dayDiff > 0) {
        if (endMoment.format("YYYY-MM-DD") === currentDate) {
          return 0;
        }
      }

      if (!startTime) {
        return 0;
      }
      const hourNumber = moment.duration(startTime).asHours();
      return hourNumber * this.itemWidth;
    },
    calculateWidth(booking) {
      const { startDate, startTime, endDate, endTime } = booking;
      const currentDate = this.getCurrentDate;
      const startMoment = moment(startDate, "YYYY-MM-DD");
      const endMoment = moment(endDate, "YYYY-MM-DD");
      let startHour = moment.duration(startTime).asHours();
      let endHour = moment.duration(endTime).asHours();
      const dayDiff = endMoment.diff(startMoment, "days", false);

      if (dayDiff > 0) {
        if (startMoment.format("YYYY-MM-DD") !== currentDate) {
          startHour = 0;
        }
        if (endMoment.format("YYYY-MM-DD") !== currentDate) {
          endHour = 24;
        }
      }

      const hourDiff =
        startHour === 0 && endHour === 0 ? 24 : endHour - startHour;

      return hourDiff * this.itemWidth;
    },
    getItemColor(type) {
      switch (type) {
        case "MEETING_ROOM":
          return "blue";
        case "CAR":
          return "red";
        default:
          return "yellow";
      }
    },
    async showDetail(e, book) {
      e.preventDefault();
      e.stopPropagation();
      const x = e.clientX,
        y = e.clientY;
      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() =>
            this.openResourceBookingView({ x: x, y: y, bookingId: book.id })
          )
        );
      };

      if (this.getShow) {
        this.closeResourceBookingView();
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    generateTimeText(booking) {
      const { startDate, startTime, endDate, endTime, allDay } = booking;
      const startMoment = moment(startDate, "YYYY-MM-DD");
      const endMoment = moment(endDate, "YYYY-MM-DD");
      const dayDiff = endMoment.diff(startMoment, "days", false);

      let txt = startDate;

      if (dayDiff === 0) {
        txt += allDay ? "" : ` ${startTime} ~ ${endTime}`;
      } else if (dayDiff > 0) {
        txt += allDay
          ? ` ~ ${endDate}`
          : ` ${startTime} ~ ${endDate} ${endTime}`;
      }

      return txt;
    },
    moveCurrentTimeLine() {
      if (this.$refs.currentTimeLine) {
        const selectedDate = this.getCurrentDate;
        const currentDate = moment().format("YYYY-MM-DD");
        if (selectedDate != currentDate) {
          this.$refs.currentTimeLine.style.display = "none";
          return;
        }
        const hours = moment().hour();
        const minutes = moment().minutes();
        const totalMinutes = hours * this.itemWidth;
        const left = totalMinutes + minutes * (this.itemWidth / 60);
        this.$refs.currentTimeLine.style.display = "block";
        this.$refs.currentTimeLine.style.left = left + "px";
      }
    },
    startDrag(event, resourceId, hour) {
      this.dragData.isDragged = true;
      this.dragData.resourceId = resourceId;
      //this.dragData.hours.push(hour);
      this.dragData.startHour = hour;
      const element = this.$refs[`item_block_${resourceId}_${hour}`];
      // 클래스 추가
      element[0].classList.add("dragged");
    },
    moveDrag(event, resourceId, hour) {
      if (!this.dragData.isDragged) {
        return;
      }
      if (this.dragData.resourceId !== resourceId) {
        return;
      }
      const element = document.querySelectorAll(".item-time-blocks");
      if (element) {
        element.forEach(el => el.classList.remove("dragged"));
      }
      // 드래그 처리할 시작, 끝 element hour
      let first = this.dragData.startHour;
      let last = hour;
      // 드래그를 역순으로 할 경우
      if (first > last) {
        const tempFirst = first;
        first = last;
        last = tempFirst;
      }
      for (let i = first; i <= last; i++) {
        const element = this.$refs[`item_block_${resourceId}_${i}`][0];
        element.classList.add("dragged");
      }
      this.dragData.endHour = hour;
    },
    endDrag() {
      if (!this.dragData.isDragged) {
        return;
      }

      let start = this.dragData.startHour;
      let end = this.dragData.endHour;

      if (start > end) {
        const tempFirst = start;
        start = end;
        end = tempFirst;
      }
      end++;
      const currentDate = this.getCurrentDate;
      const startHour = Math.floor((start * 30) / 60);
      const startMinute = (start * 30) % 60;
      const endHour = Math.floor((end * 30) / 60);
      const endMinute = (end * 30) % 60;
      this.openResourceBookingDialog({
        mode: RESOURCE_CONSTANTS.mode.save,
        resourceId: this.dragData.resourceId,
        timeData: {
          startDate: currentDate,
          endDate: currentDate,
          startTime: `${String(startHour).padStart(2, "0")}:${startMinute}`,
          endTime: `${String(endHour).padStart(2, "0")}:${endMinute}`
        }
      });
      this.dragData.isDragged = false;
      this.dragData.resourceId = 0;
      const element = document.querySelectorAll(".item-time-blocks");
      if (element) {
        element.forEach(el => el.classList.remove("dragged"));
      }
    },
    leaveDrag() {}
  }
};
</script>
<style lang="scss" scoped>
@import "../../../style/scss/_resource.scss";
/* 최상단 해더 시간 표시 */
.list-time-area {
  height: $listHeaderHeight;
  display: inline-flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid $border-color;
  div[class^="time-block-"] {
    width: $itemWidth;
    line-height: $listHeaderHeight;
    text-align: center;
    border-left: 1px solid $border-color;
    &:last-of-type {
      border-right: 1px solid $border-color;
    }
  }
}
.right-panel {
  width: calc($itemWidth * 24);
  height: fit-content;
  /* 시간 블럭 */
  .booking-row {
    min-height: $itemHeight;
    /*border-bottom: 1px solid $border-color;*/
    .item-time-blocks {
      width: calc($itemWidth / 2);
      height: $itemHeight;
      border-right: 1px solid $border-color;
      &.half-hour {
        border-right: 1px dotted $border-color;
      }
      &.dragged {
        background-color: $border-color;
      }
    }
    .resource-booking-item {
      height: $itemHeight;
    }
  }

  .booking-area {
    position: relative;
    .item-header {
      height: $listHeaderHeight;
    }
    /* 현재 시간 표시 바 */
    .current-time-line {
      width: 1px;
      background-color: #ea4335;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      /* 위아래 삼각형 */
      &::before {
        content: "";
        position: absolute;
        margin-left: -5px;
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-top: 6px solid red;
      }
      &::after {
        content: "";
        position: absolute;
        margin-left: -5px;
        width: 0px;
        height: 0px;
        bottom: 0px;
        border: 6px solid transparent;
        border-bottom: 6px solid red;
      }
    }
    .resource-empty-area {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: $itemHeight;
    }
    .item-wrap {
      min-height: $itemHeight;
    }
  }
  .text-reason {
    font-size: 12px;
  }
  .text-time {
    margin-top: 2px;
    font-size: 11px;
  }
}
</style>
