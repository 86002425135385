<template>
  <div class="left-panel">
    <div class="left-top-space"></div>
    <v-list class="py-0">
      <template v-for="(type, i) in getActualTypes">
        <!-- 아이템 카테고리 -->
        <div :key="'item_header_' + i" class="item-header ml-1">
          <v-btn
            :key="'category_toggle_' + type.value"
            rounded
            elevation="0"
            color="white"
            class="text-body-2 font-weight-bold"
            :style="{ color: getItemColor(type.value) }"
            @click="foldCategory(type)"
          >
            <v-icon left>{{
              type.isFolded ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
            {{ type.title }}
          </v-btn>
        </div>
        <v-divider :key="'line_header_' + i"></v-divider>
        <!-- 아이템 목록 -->
        <div
          class="item-wrap"
          :ref="`${type.value}_WRAP`"
          :key="'category_' + i"
        >
          <template v-for="(resource, j) in getResourcesByType(type.value)">
            <v-row class="no-gutters item-row" :key="'items_' + j">
              <div class="col-auto">
                <div
                  class="fill-height"
                  :class="getItemColor(resource.type)"
                ></div>
              </div>
              <div class="col blue-grey--text fill-height">
                <div
                  class="unavailable-resource"
                  v-if="resource.status !== 'IN_USE'"
                >
                  <span>{{ $t("resource.status.UNAVAILABLE") }}</span>
                </div>
                <v-list-item :value="resource" class="resource-item px-3 pr-1">
                  <!-- 자원명 -->
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-body-2 font-weight-bold"
                      @click="openDetailViewer(resource)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="text-truncate"
                            style="max-width:120px;"
                          >
                            {{ resource.name }}
                          </div>
                        </template>
                        <span>{{ resource.name }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- 이벤트 생성 버튼(+) -->
                  <v-list-item-action class="ma-0">
                    <v-btn
                      v-if="resource.status == 'IN_USE'"
                      icon
                      @click="openBookingDialog($event, resource)"
                    >
                      <v-icon color="grey lighten-1">mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-row>
          </template>
          <template v-if="isEmptyResources(type.value)">
            <div class="resource-empty-area"></div>
            <v-divider :key="'line_bottom_' + i"></v-divider>
          </template>
        </div>
      </template>
    </v-list>

    <v-dialog
      ref="image_viewer"
      class="image-viewer"
      v-if="showDetailViewer"
      v-model="showDetailViewer"
      light
      max-width="400px"
    >
      <v-card class="mx-auto" max-width="400px" elevation="24">
        <div class="img-wrapper">
          <img
            v-if="resourceImageSrc != ''"
            ref="image-preview"
            style="max-height: 300px; max-width: 400px;"
            :src="resourceImageSrc"
          />
          <div v-else class="no-image-div"><span>No image.</span></div>
        </div>
        <v-card-title>
          {{ selectedResource.name }}
        </v-card-title>
        <v-card-text class="mt-10">
          <v-row align="center" class="mx-0">
            <pre>{{ selectedResource.description }}</pre>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { viewImage } from "@/resource/api/resource.api";
import { mapGetters, mapActions } from "vuex";
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";
import ResourcePanelMixin from "@/resource/mixins/resourcePanelMixin";
export default {
  mixins: [ResourcePanelMixin],
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    showDetailViewer: false,
    resourceImageSrc: "",
    selectedResource: {}
  }),
  computed: {
    ...mapGetters("resource", [
      "getResources",
      "getActualTypes",
      "getResourcesByType"
    ]),
    changedResources() {
      return this.getResources;
    }
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("resourceDialog", ["openResourceBookingDialog"]),
    ...mapActions("resource", ["foldResource"]),

    getItemColor(type) {
      switch (type) {
        case "MEETING_ROOM":
          return "blue";
        case "CAR":
          return "red";
        default:
          return "yellow";
      }
    },
    openBookingDialog(e, item) {
      e.stopPropagation();
      this.openResourceBookingDialog({
        mode: RESOURCE_CONSTANTS.mode.save,
        resourceId: item.id
      });
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);

      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    foldCategory(item) {
      let isFolded = item.isFolded;
      this.foldResource({
        value: item.value,
        isFolded: !isFolded
      });
    },
    async openDetailViewer(item) {
      this.resourceImageSrc = "";
      const { data, headers } = await viewImage(item.id);
      if (data && data.size > 0) {
        const blobData = new Blob([data], {
          type: headers["content-type"]
        });
        this.resourceImageSrc = URL.createObjectURL(blobData);
      }
      this.selectedResource = item;
      this.showDetailViewer = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../style/scss/_resource.scss";

.left-panel {
  /*border-right: 1px solid $border-color;*/
  ::v-deep {
    position: sticky;
    left: 0;
    z-index: 5;
    .item-wrap {
      min-height: $itemHeight;
    }
    /* 최상단 해더 시간 표시 */
    .left-top-space {
      width: $leftPanelWidth;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 5;
      height: $listHeaderHeight;
      background-color: white;
      border-bottom: 1px solid $border-color;
    }
    /* 자원 해더 (카테고리) */
    .item-header {
      padding: 2px 0;
      height: $listHeaderHeight;
    }
    .item-row {
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
    .unavailable-resource {
      position: absolute;
      opacity: 0.4;
      background-color: #ff4c26;
      height: $itemHeight;
      width: calc($itemWidth * 24);
      margin-left: $leftPanelWidth;
      text-align: center;
      line-height: $itemHeight;
      z-index: 10;
      span {
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .resource-item {
      padding-left: 15px;
      min-height: $itemHeight;
      .resource-img {
        cursor: pointer;
      }
      .v-list-item__content {
        cursor: pointer;
      }
    }
    .resource-empty-area {
      width: 100%;
      height: $itemHeight;
    }
  }
}

.v-dialog__content ::v-deep {
  .no-image-div {
    width: 400px; /* 원하는 너비 설정 */
    height: 300px; /* 원하는 높이 설정 */
    background: #ccc; /* 배경 색상을 회색으로 설정 */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  .img-wrapper {
    text-align: center;
  }
}
</style>
