<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="date"
            class="resource-date-picker ma-0 pa-0"
            style="min-width: fit-content"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            flat
            solo
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          :value="date"
          no-title
          :day-format="formatDay"
          @input="changeDate"
        />
      </v-menu>
    </v-col>
    <v-col v-if="time" cols="12" class="pl-2">
      <v-select
        :value="time"
        :items="times"
        class="ma-0 pa-0"
        style="min-width: fit-content"
        hide-details
        flat
        solo
        menu-props="auto"
        item-color="#42A5F5"
        @change="changeTime"
      >
        <template v-slot:item="{ item }">
          <span
            class="d-flex justify-center"
            style="width: 100%; letter-spacing: 1px"
          >
            {{ item }}
          </span>
        </template>

        <template v-slot:selection="{ item }">
          <span style="width: 100%; letter-spacing: 1px">
            {{ item }}
          </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    time: {
      type: String
    }
  },
  data() {
    return {
      showDatePicker: false,
      times: []
    };
  },
  methods: {
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    changeDate(e) {
      this.showDatePicker = false;
      this.$emit("update:date", e);
    },
    changeTime(e) {
      this.$emit("update:time", e);
    }
  },
  created() {
    for (let i = 0; i < 24; i++) {
      const hour = ("" + i).padStart(2, "0");
      this.times.push(hour + ":00", hour + ":30");
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-date-picker {
  .v-icon {
    font-size: 20px;
  }
  ::v-deep .v-input__slot {
    padding: 0px !important;
  }
}
</style>
